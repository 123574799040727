import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { CakeIcon, CheckCircleIcon, ClipboardCheckIcon } from '@heroicons/react/solid'


export default function Sidebar() {
  return (

            <div className="bg-white ">

                    <div className="flex flex-col flex-grow bg-slate-400 rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
                      {/* <p className="text-base font-semibold leading-7 text-[#65BCE4]">Award winning support</p> */}
                      <h3 className="mt-2 text-4xl font-bold tracking-tight text-white">Spring Medicare</h3>
                      <p className="mt-6 text-base leading-7 text-gray-200">
                      Plus qu'un annuaire, Spring Medicare propose aux thérapeutes de nombreux services pour les aider à trouver et développer leur clientèle, faciliter la gestion de leur activité, ou encore mailler un réseau professionnel avec leurs confrères.
                      </p>
                      <p className="mt-4 leading-6 text-green-200">
                        <a href="/maison/contact/">
                          <StaticImage
                            className="bg-white rounded-md"
                            src="../../images/sidebar/Malfacon.jpg" 
                            alt=""
                          />
                        </a>
                      </p>
                      <div className="mt-8">
                        <a
                          href="/solution/membre/welcome/"
                          className="inline-flex items-center rounded-md ring-1 ring-spring-bleu bg-spring-bleu px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                          Devenez membre
                        </a>
                      </div>
                    </div>

                    {/* <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Pourquoi nous rejoindre
                          </h3>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                                <div className="relative pl-9">
                                  <dt className="inline font-semibold text-gray-900">
                                    <CakeIcon className="absolute top-1 left-1 h-5 w-5 text-[#65BCE4]" aria-hidden="true" />
                                    Push to deploy.
                                  </dt>
                                  <dd className="inline"> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.</dd>
                                </div>
                                <div className="relative pl-9">
                                  <dt className="inline font-semibold text-gray-900">
                                    <CheckCircleIcon className="absolute top-1 left-1 h-5 w-5 text-[#65BCE4]" aria-hidden="true" />
                                    SSL certificates.
                                  </dt>
                                  <dd className="inline"> Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.</dd>
                                </div>
                                <div className="relative pl-9">
                                  <dt className="inline font-semibold text-gray-900">
                                    <ClipboardCheckIcon className="absolute top-1 left-1 h-5 w-5 text-[#65BCE4]" aria-hidden="true" />
                                    Database backups.
                                  </dt>
                                  <dd className="inline"> Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.</dd>
                                </div>
                            </dl>
                          </p>
                        </div>
                      </div>
                    </div> */}
                    
            </div>



        )
    }